import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UserAvatar, { INameProvider } from "./UserAvatar";

const useStyles = makeStyles(() => ({
  primary: { fontSize: 14 },
  secondary: {
    fontSize: 11
  },
  flex: { display: "flex", alignItems: "center", height: "100%" },
  flexColumn: { display: "flex", flexDirection: "column", marginLeft: 10 }
}));

interface IUserNamesProvider {
  data: INameProvider & { name: string };
}

export default function UserName({ data: user }: IUserNamesProvider) {
  const classes = useStyles();
  const hasName = user.firstName !== null && user.lastName !== null;
  return user ? (
    <div className={classes.flex}>
      <UserAvatar user={user} />
      <div className={classes.flexColumn}>
        {hasName ? (
          <Typography
            className={classes.primary}
          >{`${user.firstName} ${user.lastName}`}</Typography>
        ) : (
          <Typography className={classes.primary}>{user.name}</Typography>
        )}
        {hasName ? (
          <Typography className={classes.secondary}>{user.name}</Typography>
        ) : null}
      </div>
    </div>
  ) : null;
}
