import { Grid, Typography } from "@mui/material";
import "../authenticator-components.css";

const AppInformation = ({ environment }: { environment: string }) => {
  return (
    <Grid item xs={12} md={7} lg={8} className="right">
      <div className="flex">
        <div className="appInfo">
          <Typography className="appTitle" variant="h2">
            Cloud Platform
          </Typography>
          <Typography className="environment">{environment}</Typography>
          <Typography className="appDescription">
            Create, manage and monitor your Use Cases
          </Typography>
        </div>
      </div>
      <Typography className="copyright">Copyright ©2023 Siemens</Typography>
    </Grid>
  );
};
export default AppInformation;
