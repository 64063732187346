import { gql, useMutation } from "@apollo/client";
import { Button, LinearProgress, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import BasicCard from "components/card/BasicCard";
import { ErrorBox } from "components/error";
import { useQueryUseCaseList } from "features/use-case";
import { Formik, FormikErrors, FormikValues } from "formik";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import defaultIcon from "tile.svg";
import { createRoute } from "utils/url";
import ChangesPreview from "./ChangesPreview";
import PublishForm from "./PublishForm";
import usePublishFormReducer, { State } from "./hooks/usePublishFormReducer";
import {
  PublishUseCase,
  PublishUseCaseVariables
} from "./schema/PublishUseCase";

const mutationPublishUseCase = gql`
  mutation PublishUseCase($useCaseDefinition: UseCaseDefinitionInput!) {
    cloudPlatform {
      addUseCase(useCaseDefinition: $useCaseDefinition) {
        detail {
          version
          key
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  const breakpointValue = 970;
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridTemplateRows: "25px 240px 80px auto",
      padding: theme.spacing(3),
      columnGap: theme.spacing(3),
      rowGap: theme.spacing(3),
      width: "80%",
      [theme.breakpoints.down(breakpointValue)]: {
        gridTemplateColumns: "100%"
      }
    },
    row1: {
      gridRow: "1 / span 1",
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    row2: { gridRow: "2 / span 1", gridRowStart: 2, gridRowEnd: 5 },
    row3: {
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "3 / span 1",
        gridRowStart: 1,
        gridRowEnd: 2,
        marginTop: 0
      },
      margin: theme.spacing(2, 0, 1, 0),
      color: theme.palette.primary.main
    },
    row4: {
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "4 / span 1",
        gridRowStart: 2,
        gridRowEnd: 3
      },
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: 350,
      height: 200
    },
    row5: {
      [theme.breakpoints.up(breakpointValue)]: {
        gridRow: "5 / span 1",
        gridRowStart: 3,
        gridRowEnd: 4
      }
    },
    flex: {
      display: "flex",
      flexDirection: "column"
    },
    indicatorWrapper: {
      position: "sticky",
      width: "100%",
      zIndex: 1300
    },
    paper: { padding: theme.spacing(3) },
    link: { display: "inline-flex", textDecoration: "none" },
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    margin: {
      [theme.breakpoints.down(breakpointValue)]: { marginTop: theme.spacing(1) }
    },
    buttons: { marginTop: theme.spacing(5) }
  });
});

interface PublishFormWrapperProps {
  initialState?: State;
  updateUseCase?: boolean;
}

export default function PublishFormWrapper({
  initialState = {
    name: "",
    key: "",
    autoGeneratedKey: true,
    description: "",
    tags: []
  },
  updateUseCase = false
}: PublishFormWrapperProps) {
  const classNames = useStyles({});

  const [
    publish,
    { loading, error: publishUseCaseError, reset: resetPublishUseCaseError }
  ] = useMutation<PublishUseCase, PublishUseCaseVariables>(
    mutationPublishUseCase,
    {
      onCompleted: mutationResult => {
        const createdUseCase = mutationResult.cloudPlatform.addUseCase?.detail;
        if (!createdUseCase) return;
        const { key, version } = createdUseCase;
        history.push(
          createRoute(`/use-case-manager/use-case/${key}@${version}/create`)
        );
        history.push(createRoute(`/use-cases/${key}/${version}`));
      }
    }
  );

  const { data: allUseCases } = useQueryUseCaseList();
  const [state, dispatch] = usePublishFormReducer(initialState);

  const history = useHistory();

  const { name, image, description, tags, calculationRuleAddress, key } = state;
  const initialValues = {
    name,
    key,
    description,
    calculationRuleAddress: ""
  };

  const allUseCasesKeys = allUseCases?.cloudPlatform.useCases?.map(
    ({ detail: { key, version } }) => ({ key, version })
  );

  const isExistedKey = (a: string) => allUseCasesKeys?.find(c => c.key === a);
  const [debouncedKey, setDebouncedKey] = useState(key);
  const delayed = useRef(debounce(setDebouncedKey, 500)).current;

  useEffect(() => {
    delayed(key);
  }, [delayed, key]);

  const isValidName = (value: string) => {
    return value
      .split("")
      .map(c => c.charCodeAt(0))
      .every(c => c <= 127);
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};

    if (!updateUseCase) {
      if (values.key === "") {
        errors.key = "Use case Key is required";
      } else if (isExistedKey(values.key)) {
        errors.key = "This key already exists. You can publish a new version";
      } else if (/[^A-Za-z0-9_.-]/.test(values.key)) {
        errors.key = "Key must only contain lowercase letters and digits";
      }
      if (
        values.name &&
        (values.key || key) &&
        !isExistedKey(values.key) &&
        !values.calculationRuleAddress
      ) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    } else {
      if (values.name && !values.calculationRuleAddress) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    }
    if (values.name === "") {
      errors.name = "Use case Name is required";
    } else if (!isValidName(values.name)) {
      errors.name = "Name must have only Ascii characters";
    }

    return errors;
  };
  return (
    <>
      <div className={classNames.indicatorWrapper}>
        {loading ? <LinearProgress /> : null}
      </div>
      <div className={classNames.root}>
        <Paper className={classNames.grid}>
          <Typography className={classNames.row1}>NEW USE CASE</Typography>
          <div className={classNames.row2}>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {({
                submitForm,
                isSubmitting,
                dirty,
                isValid,
                handleReset,
                ...restOfProps
              }) => {
                return (
                  <div className={classNames.flex}>
                    <PublishForm
                      {...restOfProps}
                      state={state}
                      dispatch={dispatch}
                      updateUseCase={updateUseCase}
                      version={isExistedKey(key)?.version}
                    />
                    <div className={classNames.buttons}>
                      <Link
                        className={classNames.link}
                        to={createRoute(`/use-cases`)}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          className={classNames.cancelButton}
                        >
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        disabled={!dirty || !isValid}
                        id="publish-use-case"
                        variant="contained"
                        classes={{
                          root: classNames.createButton,
                          disabled: classNames.disabledButton
                        }}
                        className={classNames.createButton}
                        data-testid={
                          !updateUseCase
                            ? "publish-use-case"
                            : "publish-use-case-version"
                        }
                        onClick={() => {
                          submitForm();
                          if (!state.calculationRuleAddress) return;
                          publish({
                            variables: {
                              useCaseDefinition: {
                                calculationRuleAddress:
                                  state.calculationRuleAddress,
                                key: state.key,
                                name: state.name,
                                description: state.description,
                                image: state.image,
                                tags: state.tags
                              }
                            }
                          });
                        }}
                      >
                        {!updateUseCase
                          ? "Create Use Case"
                          : "Create new version"}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
          <Typography align="left" className={classNames.row3}>
            PREVIEW
          </Typography>
          <div className={classNames.row4} data-testid="preview">
            <BasicCard
              name={name}
              key={key}
              tags={tags}
              icon={image ? image : defaultIcon}
              description={description}
            />
          </div>
          {calculationRuleAddress && (
            <div className={classNames.row5}>
              <ChangesPreview
                useCaseKey={debouncedKey}
                calculationRuleAddress={calculationRuleAddress}
              />
            </div>
          )}
          {publishUseCaseError && (
            <div className={classNames.margin}>
              <ErrorBox
                apolloError={publishUseCaseError}
                title="Use case publish failed!"
                onClose={() => resetPublishUseCaseError()}
                closable
                maxHeight={updateUseCase ? 80 : 130}
              />
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}
