import { Button, Divider, Flex } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { isEmpty } from "lodash";
import { getConfigValue } from "utils/config";
import "../authenticator-components.css";

const CustomSSOButtons = () => {
  const siemensProvider = getConfigValue("FEDERATED_SIEMENS_PROVIDER");
  const customerProvider = getConfigValue("FEDERATED_CUSTOMER_PROVIDER");
  const environmentName = (getConfigValue("ENVIRONMENT") || "").replace(
    "_",
    " "
  );

  const hasFederatedSiemensLogin = siemensProvider && !isEmpty(siemensProvider);
  const hasFederatedCustomerLogin =
    customerProvider && !isEmpty(customerProvider);

  return (
    <Flex padding={`0 32px`} direction={"column"}>
      {hasFederatedSiemensLogin && (
        <>
          <Button
            className="sso-button"
            data-testid="sso-siemens"
            onClick={event => {
              event.preventDefault();
              Auth.federatedSignIn({
                customProvider: siemensProvider
              });
            }}
          >
            SSO (Siemens)
          </Button>
          {!customerProvider && <Divider label="or" />}
        </>
      )}
      {hasFederatedCustomerLogin && environmentName && (
        <>
          <Button
            className="sso-button"
            data-testid="sso-customer"
            onClick={event => {
              event.preventDefault();
              Auth.federatedSignIn({
                customProvider: customerProvider
              });
            }}
          >
            {`SSO (${environmentName})`}
          </Button>
          <Divider label="or" />
        </>
      )}
    </Flex>
  );
};
export default CustomSSOButtons;
