import { InfoOutlined } from "@mui/icons-material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Divider, IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LinkIconButton from "components/base/LinkIconButton";
import { useRef, useState } from "react";
import ActionBar from "../../ActionBar";
import EnvironmentDetails from "./EnvironmentDetails";

//@ts-ignore
const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    display: "flex",
    alignItems: "center"
  },
  divider: {
    height: 48,
    marginLeft: 12
  },
  iconButton: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      borderRadius: 0,
      height: 48
    }
  },

  icon: { color: theme.palette.secondary.main }
}));
export default function NavigationActions({
  children
}: {
  children: React.ReactNode;
}) {
  const { root, divider, iconButton, icon } = useStyles({});

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(true);
  };

  return (
    <div className={root}>
      <ActionBar />
      <LinkIconButton
        to={"/third-party-notice/ReadMe_OSS.html"}
        title="Third Party Notice"
        testId="third-party-notice"
        icon={InfoOutlined}
        target="_blank"
      />
      <EnvironmentDetails
        anchorEl={anchorRef.current}
        open={open}
        title="Current environment changelog"
        closePopper={() => {
          setOpen(false);
        }}
      >
        {title => (
          <>
            <Tooltip title={<Typography>{title}</Typography>}>
              <IconButton
                onClick={handleToggle}
                className={iconButton}
                ref={anchorRef}
                size="large"
              >
                <ScheduleIcon className={icon} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </EnvironmentDetails>

      <Divider orientation="vertical" className={divider} />
      {children}
    </div>
  );
}
