import { Authenticator, Flex, useAuthenticator } from "@aws-amplify/ui-react";
import { Link } from "@mui/material";
import CustomSSOButtons from "./CustomSSOButtons";
import logo from "./logo_petrol.svg";

const placeholder = "";
const CustomAuthenticator = () => {
  return (
    <Authenticator
      components={{
        SignIn: {
          Header() {
            return (
              <>
                <Flex>
                  <img src={logo} alt="logo" className="logo" />
                </Flex>
                <CustomSSOButtons />
              </>
            );
          },
          Footer() {
            const { toResetPassword, toSignUp } = useAuthenticator();
            return (
              <div className="forgetPasswordFooter">
                <div className="forgetPassword">
                  Forgot your password?{" "}
                  <Link
                    data-test="sign-in-forgot-password-link"
                    className="link"
                    color="primary"
                    onClick={toResetPassword}
                  >
                    Reset password
                  </Link>
                </div>

                <span className="footerSecondaryContent">
                  No account?{" "}
                  <Link
                    data-test="sign-in-create-account-link"
                    className="link"
                    color="primary"
                    onClick={toSignUp}
                  >
                    Create account
                  </Link>
                </span>
              </div>
            );
          }
        },
        SignUp: {
          Header() {
            return (
              <Flex>
                <img src={logo} alt="logo" className="logo" />
              </Flex>
            );
          },
          Footer() {
            const { toSignIn } = useAuthenticator();
            return (
              <div className="signUpFooter">
                <div className="singInLink">
                  Have an account?{" "}
                  <Link
                    data-test="sign-in-forgot-password-link"
                    className="link"
                    color="primary"
                    onClick={toSignIn}
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            );
          }
        }
      }}
      formFields={{
        signIn: {
          username: {
            placeholder,
            isRequired: true,
            label: "Email Address *"
          },
          password: {
            label: "Password *",
            placeholder,
            autocomplete: "on"
          }
        },
        signUp: {
          name: {
            label: "First Name",
            isRequired: true,
            type: "string",
            order: 1,
            placeholder
          },
          family_name: {
            label: "Last Name",
            isRequired: true,
            type: "string",
            order: 2,
            placeholder
          },
          username: {
            label: "Email Address",
            isRequired: true,
            order: 3,
            type: "email",
            placeholder
          },
          email: {
            label: "Confirm Email Address",
            isRequired: true,
            order: 4,
            type: "email",
            placeholder
          },
          password: {
            label: "Password",
            isRequired: true,
            order: 5,
            type: "password",
            placeholder,
            autocomplete: "on"
          },
          confirm_password: {
            isRequired: true,
            order: 6,
            placeholder
          }
        }
      }}
    />
  );
};

export default CustomAuthenticator;
